
import { defineComponent, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CardAttachment from "@/components/CardAttachment.vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";

interface APIData {
  returned_voucher_base64: string;
}

export default defineComponent({
  name: "card-return-voucher-upload",
  components: {
    CardAttachment,
  },
  props: ["invoice_id", "returned_voucher_base64"],
  data() {
    return {
      auth: {
        id_token: "",
      },
    };
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const ReturnVoucherUploadModalRef = ref<null | HTMLElement>(null);
    const { t, te } = useI18n();

    const state = reactive({
      loader: false,
    });

    const apiData = ref<APIData>({
      returned_voucher_base64: props.returned_voucher_base64,
    });

    const translate = function (text: string) {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const handleAttachmentChange = function (event) {
      apiData.value.returned_voucher_base64 = event.attachment;
    };

    const hideReturnVoucherModal = function () {
      hideModal(ReturnVoucherUploadModalRef.value);
    };

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }

      const data = {
        returned_voucher_base64: apiData.value.returned_voucher_base64,
      };
      state.loader = true;

      ApiService.post(
        "/invoices/returned-voucher/" + props.invoice_id + "/upload",
        data
      )
        .then(() => {
          Swal.fire({
            text: translate("app.success_msg"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: translate("app.ok"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        })
        .catch(() => {
          Swal.fire({
            text: translate("app.invalid_credentials"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: translate("app.try_again"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          state.loader = false;
          hideModal(ReturnVoucherUploadModalRef.value);
        });
    };

    return {
      apiData,
      submit,
      hideReturnVoucherModal,
      submitButtonRef,
      modalRef,
      state,
      translate,
      ReturnVoucherUploadModalRef,
      handleAttachmentChange,
    };
  },
});

<template>
  <div>
    <BulkAction
      style="position: relative"
      v-if="hasBulkAction"
      :bus="bus"
      :hasPrintCompanyInvoices="hasPrintCompanyInvoices"
      :bulkLoading="bulkLoading"
      :exportLoading="exportLoading"
      :addNew="addNew"
      :hasExport="hasExport"
      :permissions="permissions"
      :refs="refs"
      :search="search"
      :category_id="category_id"
      :time="time"
      :date="date"
      :target="target"
      :status="status"
      v-on:activeChange="handelActiveChange"
      v-on:inactiveChange="handelInActiveChange"
      v-on:fetchDate="fetchData(true)"
      v-on:refreshTable="fetchData"
      :fields="fields"
      :hasReportDropDown="hasReportDropDown"
      :selected="selected"
    >
    </BulkAction>

    <div class="card-body">
      <div class="row mb-2">
        <div
          class="col-sm-12 row"
          :style="$i18n.locale == 'ar' ? '' : 'text-align: left'"
        >
          <h5 :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'mrl-5'">
            {{ $t("app.all_rows") }}
            <span v-if="dataLoading">
              <span
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              >
              </span>
            </span>
            <span v-if="!dataLoading">( {{ total }} )</span>
          </h5>
          <h5
            v-if="hasPaidCountStatistics"
            :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'mrl-5'"
          >
            {{ $t("view.pending") }}
            <span v-if="dataLoading">
              <span
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
            </span>
            <span class="mr-3" v-if="!dataLoading"
              >( {{ pending_count }} )</span
            >
            {{ $t("view.returned") }}
            <span v-if="dataLoading">
              <span
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
            </span>
            <span class="mr-3" v-if="!dataLoading"
              >( {{ returned_count }} )</span
            >
            {{ $t("view.paid") }}
            <span v-if="dataLoading">
              <span
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
            </span>
            <span class="mr-3" v-if="!dataLoading">( {{ paid_count }} )</span>
          </h5>
          <h5
            v-if="hasPaidAmountStatistics"
            :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'mrl-5'"
          >
            {{ $t("chart.pending_amount") }}
            <span v-if="dataLoading">
              <span
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
            </span>
            <span class="mr-5" v-if="!dataLoading"
              >( {{ pending_amount }} )</span
            >
            {{ $t("chart.returned_amount") }}
            <span v-if="dataLoading">
              <span
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
            </span>
            <span class="mr-5" v-if="!dataLoading"
              >( {{ returned_amount }} )</span
            >
            {{ $t("chart.paid_amount") }}
            <span v-if="dataLoading">
              <span
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
            </span>
            <span class="mr-3" v-if="!dataLoading">( {{ paid_amount }} )</span>
          </h5>

          <div
            v-if="hasSearchFilter"
            class="search-box mb-2 d-inline-block col-3 col-sm-12"
          >
            <div class="position-relative">
              <form @submit.prevent="fetchData">
                <input
                  type="text"
                  class="form-control"
                  :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                  :placeholder="lblSearch"
                  v-model="search"
                />
                <i class="bx bx-search-alt search-icon"></i>
              </form>
            </div>
          </div>

          <div
            v-if="hasRoleFilter && !roleLoading"
            class="search-box ml-3 d-inline-block col-3 col-sm-6"
          >
            <div class="position-relative">
              <select
                class="form-control"
                v-model="role"
                @change="fetchData"
                :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
              >
                <option value="">{{ $t("view.role_name") }}</option>
                <option
                  v-for="(role, idx) in roles"
                  :key="idx"
                  :value="role.name"
                >
                  {{ role.name }}
                </option>
              </select>
            </div>
          </div>

          <div
            v-if="hasCompanyFilter && companies.length"
            class="search-box ml-3 d-inline-block col-3 col-sm-6"
          >
            <div class="position-relative">
              <select
                class="form-control"
                v-model="company_id"
                @change="fetchData"
                :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
              >
                <option value="">{{ $t("view.company_name") }}</option>
                <option
                  v-for="(company, idx) in companies"
                  :key="idx"
                  :value="company.encrypt_id"
                >
                  {{ company.name }}
                </option>
              </select>
            </div>
          </div>

          <div
            v-if="hasTimeFromFilter"
            class="search-box d-inline-block col-3 col-sm-6"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
          >
            <div class="position-relative">
              <input
                type="time"
                class="form-control"
                v-model="time_from"
                @change="fetchData"
              />
              <span class="search-icon">
                <i
                  v-if="time_from"
                  @click="clearTimeFrom"
                  class="fa fa-times cursor-pointer font-size-13"
                ></i>
              </span>
            </div>
          </div>

          <div
            v-if="hasTimeToFilter"
            class="search-box d-inline-block col-3 col-sm-6"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
          >
            <div class="position-relative">
              <input
                type="time"
                class="form-control"
                v-model="time_to"
                @change="fetchData"
              />
              <span class="search-icon">
                <i
                  v-if="time_to"
                  @click="clearTimeTo"
                  class="fa fa-times cursor-pointer font-size-13"
                ></i>
              </span>
            </div>
          </div>

          <div
            v-if="hasDateFilter"
            class="search-box d-inline-block col-3 col-sm-6"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
          >
            <div class="position-relative">
              <input
                type="date"
                class="form-control"
                v-model="date"
                @change="fetchData"
              />
              <span class="search-icon">
                <i
                  v-if="date"
                  @click="clearDate"
                  class="fa fa-times cursor-pointer font-size-13"
                ></i>
              </span>
            </div>
          </div>

          <div
            v-if="hasDateFromFilter"
            class="search-box d-inline-block col-3 col-sm-6"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
          >
            <div class="position-relative">
              <input
                type="date"
                class="form-control"
                v-model="date_from"
                @change="fetchData"
              />
              <span class="search-icon">
                <i
                  v-if="date_from"
                  @click="clearDateFrom"
                  class="fa fa-times cursor-pointer font-size-13"
                ></i>
              </span>
            </div>
          </div>

          <div
            v-if="hasDateToFilter"
            class="search-box d-inline-block col-3 col-sm-6"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
          >
            <div class="position-relative">
              <input
                type="date"
                class="form-control"
                v-model="date_to"
                @change="fetchData"
              />
              <span class="search-icon">
                <i
                  v-if="date_to"
                  @click="clearDateTo"
                  class="fa fa-times cursor-pointer font-size-13"
                ></i>
              </span>
            </div>
          </div>

          <div
            v-if="hasPaidFilter"
            class="search-box d-inline-block col-3 col-sm-6"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
          >
            <div class="position-relative">
              <select
                class="form-control"
                v-model="paid"
                @change="fetchData"
                :class="$i18n.locale == 'ar' ? 'font-size-15' : 'pd-10'"
              >
                <option value="">{{ $t("app.all") }}</option>
                <option value="paid">{{ $t("view.paid") }}</option>
                <option value="pending" v-html="$t('view.pending')"></option>
                <option value="returned" v-html="$t('view.returned')"></option>
              </select>
            </div>
          </div>

          <div
            v-if="hasStatusFilter"
            class="search-box d-inline-block col-3 col-sm-2"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
          >
            <div class="position-relative">
              <select
                class="form-control"
                v-if="hasStatusApproved"
                v-model="status"
                @change="fetchData"
                :class="$i18n.locale == 'ar' ? 'font-size-15' : 'pd-10'"
              >
                <option value="">{{ $t("app.status") }}</option>
                <option value="new" v-html="$t('app.new')"></option>
                <option value="approved" v-html="$t('app.approved')"></option>
                <option
                  value="unapproved"
                  v-html="$t('app.unapproved')"
                ></option>
                <option value="delayed" v-html="$t('app.has_delayed')"></option>
              </select>
              <select
                class="form-control"
                v-else
                v-model="status"
                @change="fetchData"
                :class="$i18n.locale == 'ar' ? 'font-size-15' : 'pd-10'"
              >
                <option value="">{{ $t("app.status") }}</option>
                <option
                  value="active"
                  v-html="hasSeen ? $t('app.seen') : $t('app.active')"
                ></option>
                <option
                  value="inactive"
                  v-html="hasSeen ? $t('app.unseen') : $t('app.inactive')"
                ></option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table id="printMe" class="table align-middle gs-0 gy-4">
          <thead>
            <tr
              :class="[
                headerTheme === 'light' && 'table-header-tr-light',
                headerTheme === 'dark' && 'table-header-tr-dark',
              ]"
              class="fw-bolder no-border"
            >
              <th v-if="hasHashId" class="ps-4 min-w-20px">#</th>

              <th
                v-if="hasInvoiceKey"
                class="ps-4 min-w-100px"
                :class="['font-size-14']"
              >
                {{ invoice_key }}
              </th>

              <th
                v-if="hasSubject"
                class="ps-4 min-w-100px"
                :class="[
                  $i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14',
                ]"
              >
                {{ subject }}
              </th>

              <th
                v-if="hasImage"
                class="ps-4 min-w-70px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ image }}
              </th>

              <th
                v-if="hasUser"
                class="ps-4 min-w-70px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ image }}
              </th>

              <th
                v-if="hasCompany"
                class="ps-4 min-w-70px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ company }}
              </th>

              <th
                v-if="hasPaymentStatus"
                class="ps-4 min-w-70px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ payment_status }}
              </th>

              <th
                v-if="hasPaymentMethod"
                class="ps-4 min-w-70px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ $t("app.payment_method") }}
              </th>

              <th
                v-if="hasTitle"
                class="ps-4 min-w-100px"
                :class="[
                  $i18n.locale == 'ar'
                    ? 'font-size-16'
                    : 'font-size-14 text-center',
                  hasImage || hasUser || hasCompany ? 'text-center' : '',
                ]"
              >
                {{ title }}
              </th>

              <th
                v-if="hasName"
                class="ps-4 min-w-100px"
                :class="[
                  $i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14',
                ]"
              >
                {{ name }}
              </th>

              <th
                v-if="hasModule"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ module }}
              </th>

              <th
                v-if="hasDetail"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ detail }}
              </th>

              <th
                v-if="hasStartDate"
                class="ps-4 min-w-50px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ start_date }}
              </th>

              <th
                v-if="hasEndDate"
                class="ps-4 min-w-50px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ end_date }}
              </th>

              <th
                v-if="hasPrice"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? 'font-size-16 ' : 'font-size-14'"
              >
                {{ price }}
              </th>

              <th
                v-if="hasEmail"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? ' font-size-16' : 'font-size-14'"
              >
                {{ email }}
              </th>

              <th
                v-if="hasCivilID"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? ' font-size-16' : 'font-size-14'"
              >
                {{ civilID }}
              </th>

              <th
                v-if="hasCountryCode"
                style="width: 5%"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? ' font-size-16' : 'font-size-14'"
              >
                {{ countryCode }}
              </th>

              <th
                v-if="hasMobile"
                style="direction: rtl"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? ' font-size-16' : 'font-size-14'"
              >
                {{ mobile }}
              </th>

              <th
                v-if="hasRole"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? ' font-size-16' : 'font-size-14'"
              >
                {{ roleLabel }}
              </th>

              <th
                v-if="hasDate"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? 'font-size-16 ' : 'font-size-14'"
              >
                {{ $t("app.date") }}
              </th>

              <th
                v-if="hasPaid"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? 'font-size-16 ' : 'font-size-14'"
              >
                {{ $t("app.paid") }}
              </th>

              <th
                v-if="hasStatus || hasStatus2"
                class="ps-4 min-w-100px"
                :class="$i18n.locale == 'ar' ? 'font-size-16 ' : 'font-size-14'"
              >
                {{ $t("app.status") }}
              </th>

              <th
                v-if="hasShareButtons"
                class="ps-4 min-w-200px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ $t("app.share_links") }}
              </th>
              <th
                v-if="hasTotalPaidInvoicesCount"
                class="ps-4 min-w-50px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ $t("chart.paid_invoices") }}
              </th>

              <th
                v-if="hasActions"
                class="ps-4 min-w-200px"
                :class="$i18n.locale == 'ar' ? 'font-size-16' : 'font-size-14'"
              >
                {{ $t("app.actions") }}
              </th>
            </tr>
          </thead>

          <tbody v-if="dataLoading">
            <tr>
              <td :colspan="colspan" class="text-center">
                <span
                  class="spinner-grow spinner-grow-sm mr-1"
                  role="status"
                  aria-hidden="true"
                >
                </span>
              </td>
            </tr>
          </tbody>

          <tbody v-if="!dataLoading && !items.length">
            <tr>
              <td
                :colspan="colspan"
                class="text-center"
                :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
              >
                <span>{{ $t("app.no_data") }}</span>
              </td>
            </tr>
          </tbody>

          <tbody v-if="!dataLoading && items.length">
            <tr v-if="invoiceSocialLoading">
              <td :colspan="colspan" class="text-center">
                <span>
                  <span
                    class="spinner-grow spinner-grow-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </span>
              </td>
            </tr>
            <template v-for="(item, idx) in items" :key="idx">
              <tr>
                <td v-if="hasHashId">
                  {{ idx + 1 }}
                </td>

                <td
                  v-if="hasInvoiceKey"
                  :class="[$i18n.locale == 'ar' ? 'font-size-14' : '']"
                >
                  <div class="d-flex align-items-center">
                    <div class="d-flex justify-content-start flex-column">
                      <router-link
                        v-if="
                          item.payment_status == 'pending' ||
                          item.payment_status == undefined
                        "
                        :to="{
                          name: 'edit-' + refs,
                          params: { id: item.encrypt_id },
                        }"
                        class="text-dark fw-bolder text-hover-primary mb-1 fs-7"
                        >{{ item.invoice_key }}</router-link
                      >
                      <span v-else>
                        {{ item.invoice_key }}
                      </span>
                    </div>
                  </div>
                </td>

                <td
                  v-if="hasSubject"
                  :class="[$i18n.locale == 'ar' ? 'font-size-14' : '']"
                >
                  <div class="d-flex align-items-center">
                    <div class="d-flex justify-content-start flex-column">
                      <router-link
                        v-if="
                          item.payment_status == 'pending' ||
                          item.payment_status == undefined
                        "
                        :to="{
                          name: 'edit-' + refs,
                          params: { id: item.encrypt_id },
                        }"
                        class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                        >{{ item.subject }}</router-link
                      >
                      <span v-else>
                        {{ item.subject }}
                      </span>
                    </div>
                  </div>
                </td>

                <td v-if="hasImage">
                  <router-link
                    v-if="permissions.edit"
                    :to="{
                      name: 'edit-' + refs,
                      params: { id: item.encrypt_id },
                    }"
                  >
                    <img
                      :src="item.image ? item.image.url : ''"
                      :style="image_style"
                    />
                  </router-link>

                  <img
                    v-if="!permissions.edit"
                    :src="item.image ? item.image.url : ''"
                    :style="image_style"
                  />
                </td>

                <td v-if="hasUser">
                  <div v-if="item.user" class="d-flex align-items-center">
                    <div class="d-flex justify-content-start flex-column">
                      <router-link
                        v-if="item.profile"
                        :to="{
                          name: 'edit-users',
                          params: {
                            id: item.user.encrypt_id,
                          },
                        }"
                        class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                      >
                        <span
                          class="text-muted fw-bold text-muted d-block fs-7"
                          >{{ item.user.name }}</span
                        >
                        <span
                          class="text-muted fw-bold text-muted d-block fs-7"
                          >{{ item.user.email }}</span
                        >
                        <span
                          class="
                            text-muted
                            fw-bold
                            text-muted
                            d-block
                            fs-7
                            phone
                          "
                          >{{ item.user.phone }}</span
                        >
                      </router-link>
                      <div v-else>
                        <span
                          class="text-muted fw-bold text-muted d-block fs-7"
                          >{{ item.user.name }}</span
                        >
                        <span
                          class="text-muted fw-bold text-muted d-block fs-7"
                          >{{ item.user.email }}</span
                        >
                        <span
                          class="
                            text-muted
                            fw-bold
                            text-muted
                            d-block
                            fs-7
                            phone
                          "
                          >{{ item.user.phone }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div v-else class="disabled">-</div>
                </td>
                <td v-if="hasCompany">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50px me-5">
                      <span class="symbol-label bg-light">
                        <img
                          :src="item.company ? item.company.url : ''"
                          class="h-75 align-self-end"
                          alt=""
                        />
                      </span>
                    </div>

                    <div class="d-flex justify-content-start flex-column">
                      <router-link
                        :to="{
                          name: 'edit-companies',
                          params: {
                            id: item.company.encrypt_id,
                          },
                        }"
                        class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                        >{{
                          item.company ? item.company.name : ""
                        }}</router-link
                      >
                      <span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        >{{ item.company ? item.company.email : "" }}</span
                      >
                    </div>
                  </div>
                </td>

                <td
                  v-if="hasTitle"
                  :class="[$i18n.locale == 'ar' ? 'font-size-14' : '']"
                >
                  <router-link
                    v-if="permissions.edit"
                    :to="{
                      name: 'edit-' + refs,
                      params: { id: item.encrypt_id },
                      query: { parent_id: parent_id, slug: slug },
                    }"
                    class="default-color"
                  >
                    {{ item.title }}
                  </router-link>

                  <span v-if="!permissions.edit">
                    {{ item.title }}
                  </span>
                </td>

                <td
                  v-if="hasPaymentStatus"
                  :class="[$i18n.locale == 'ar' ? 'font-size-14' : '']"
                >
                  <label
                    v-if="item.payment_status == 'pending'"
                    class="badge badge-light-warning"
                    :class="
                      $i18n.locale == 'ar' ? 'font-size-14' : 'font-size-13'
                    "
                    style="padding: 10px; margin-bottom: 0"
                    v-html="$t('view.pending')"
                  >
                  </label>
                  <label
                    v-if="item.payment_status == 'returned'"
                    class="badge badge-light-danger"
                    :class="
                      $i18n.locale == 'ar' ? 'font-size-14' : 'font-size-13'
                    "
                    style="padding: 10px; margin-bottom: 0"
                    v-html="$t('view.returned')"
                  >
                  </label>
                  <label
                    v-if="item.payment_status == 'paid'"
                    class="badge badge-light-success"
                    :class="
                      $i18n.locale == 'ar' ? 'font-size-14' : 'font-size-13'
                    "
                    style="padding: 10px; margin-bottom: 0"
                    v-html="$t('view.paid')"
                  >
                  </label>
                </td>

                <td
                  v-if="hasPaymentMethod"
                  :class="[$i18n.locale == 'ar' ? 'font-size-14' : '']"
                >
                  <label
                    class="badge badge-light-success"
                    :class="
                      $i18n.locale == 'ar' ? 'font-size-14' : 'font-size-13'
                    "
                    style="padding: 10px; margin-bottom: 0"
                    v-html="item.payment_method"
                  >
                  </label>
                </td>

                <td
                  v-if="hasName"
                  :class="[$i18n.locale == 'ar' ? 'font-size-14' : '']"
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50px me-5" v-if="item.image">
                      <span class="symbol-label bg-light">
                        <img
                          :src="item.image.url"
                          class="h-75 align-self-end"
                          alt=""
                        />
                      </span>
                    </div>

                    <div class="d-flex justify-content-start flex-column">
                      <router-link
                        :to="{
                          name: 'edit-' + this.refs,
                          params: { id: item.encrypt_id },
                        }"
                        class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                        >{{ item.name }}</router-link
                      >
                    </div>
                  </div>
                </td>

                <td
                  v-if="hasModule"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  <label
                    class="badge badge-light-success text-center"
                    style="padding: 10px; font-size: 13px"
                  >
                    {{ $t("nav." + item.model_type) }}
                  </label>
                  <br />
                  <label
                    v-if="item.model_id"
                    class="badge badge-light-success text-center"
                    style="padding: 10px; font-size: 13px"
                  >
                    {{ item.item_name }}
                  </label>
                </td>

                <td
                  v-if="hasDetail"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  <label
                    class="badge badge-light-danger text-center"
                    style="padding: 10px; font-size: 13px"
                  >
                    {{ item.detail }}
                  </label>
                </td>

                <td
                  v-if="hasStartDate"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  <label
                    class="badge badge-light-primary text-center"
                    style="font-size: 13px"
                  >
                    {{ item.start_date }}
                  </label>
                </td>

                <td
                  v-if="hasEndDate"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  <label
                    class="badge badge-light-primary text-center"
                    style="font-size: 13px"
                  >
                    {{ item.end_date }}
                  </label>
                </td>

                <td
                  v-if="hasPrice"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  <label
                    class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    style="padding: 10px"
                    :class="
                      $i18n.locale == 'ar' ? 'font-size-13' : 'font-size-12'
                    "
                  >
                    {{ item.price }}
                  </label>
                </td>

                <td
                  v-if="hasEmail"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  {{ item.email }}
                </td>

                <td
                  v-if="hasCivilID"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  {{ item.civil_id }}
                </td>

                <td
                  v-if="hasCountryCode"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  {{ item.country_code || item.code }}
                </td>

                <td
                  v-if="hasMobile"
                  class="phone"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  {{ item.country_code + (item.mobile || item.phone) }}
                </td>

                <td
                  v-if="hasRole"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  <label
                    class="badge badge-light-danger"
                    style="padding: 10px; font-size: 13px"
                  >
                    {{ item.role_name }}
                  </label>
                </td>

                <td
                  v-if="hasDate"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  <span
                    :style="item.delayed ? 'text-decoration:line-through' : ''"
                  >
                    {{ item.date ? item.date : item.invoice_date }}
                  </span>
                  <br /><span v-if="item.delayed" style="">{{
                    item.delayed_date
                  }}</span>
                </td>

                <td
                  v-if="hasPaid"
                  :class="$i18n.locale == 'ar' ? 'font-size-14' : ''"
                >
                  <span
                    v-if="item.paid"
                    class="badge badge-light-info font-size-13"
                  >
                    {{ item.payment_method }}
                    <i class="fa fa-check-circle font-size-16"></i>
                  </span>
                  <span v-else>-</span>

                  <p
                    v-if="
                      item.approved_by_user == 2 ||
                      (item.status == 2 && item.paid)
                    "
                  >
                    <span class="badge badge-light-success font-size-13 mt-1">
                      Refunded
                    </span>
                  </p>
                </td>

                <td v-if="hasStatus">
                  <label
                    v-if="item.status == 1"
                    class="badge badge-light-success"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'font-size-14'
                        : 'font-size-13 ml-1'
                    "
                    style="padding: 10px; margin-bottom: 0"
                    v-html="$t('app.active')"
                  >
                  </label>
                  <label
                    v-if="item.status == 0"
                    class="badge badge-light-primary"
                    :class="
                      $i18n.locale == 'ar' ? 'font-size-14' : 'font-size-13'
                    "
                    style="padding: 10px; margin-bottom: 0"
                    v-html="$t('app.inactive')"
                  >
                  </label>
                  <label
                    v-if="item.status == 2"
                    class="badge badge-light-danger"
                    :class="
                      $i18n.locale == 'ar' ? 'font-size-14' : 'font-size-13'
                    "
                    style="padding: 10px; margin-bottom: 0"
                    v-html="$t('app.inactive')"
                  >
                  </label>
                </td>

                <td v-if="hasStatus2">
                  <label
                    v-if="item.status"
                    class="badge badge-light-danger"
                    :class="
                      $i18n.locale == 'ar' ? 'font-size-14' : 'font-size-13'
                    "
                    style="padding: 10px"
                  >
                    {{ $t("app.seen") }}
                  </label>

                  <label
                    v-if="!item.status"
                    class="badge badge-light-success"
                    :class="
                      $i18n.locale == 'ar' ? 'font-size-14' : 'font-size-13'
                    "
                    style="padding: 10px"
                  >
                    {{ $t("app.unseen") }}
                  </label>
                </td>
                <td v-if="hasTotalPaidInvoicesCount" class="text-center">
                  <label
                    class="badge badge-light-primary"
                    :class="
                      $i18n.locale == 'ar' ? 'font-size-14' : 'font-size-13'
                    "
                    style="padding: 10px"
                  >
                    {{ item.paid_invoices }}
                  </label>
                </td>

                <td v-if="hasShareButtons" :key="item.id">
                  <button
                    type="button"
                    @click="sendInvoiceSocial(1, item)"
                    class="btn btn-bg-danger btn-active-color-primary btn-sm"
                    style="width: 32px; height: 32px; padding: 5px 10px"
                    :style="[
                      $i18n.locale == 'ar'
                        ? { 'margin-left': '5px' }
                        : { 'margin-right': '5px' },
                    ]"
                    :title="$t('app.sendEmail')"
                  >
                    <i class="mdi mdi-mail font-size-16"></i>
                  </button>
                  <button
                    type="button"
                    @click="sendInvoiceSocial(4, item)"
                    class="btn btn-bg-success btn-active-color-warning btn-sm"
                    style="width: 32px; height: 32px; padding: 5px 10px"
                    :style="[
                      $i18n.locale == 'ar'
                        ? { 'margin-left': '5px' }
                        : { 'margin-right': '5px' },
                    ]"
                    :title="$t('app.sendWhatsapp')"
                  >
                    <i class="mdi mdi-whatsapp font-size-16"></i>
                  </button>
                  <button
                    type="button"
                    @click="sendInvoiceSocial(5, item)"
                    class="btn btn-bg-warning btn-active-color-success btn-sm"
                    style="width: 32px; height: 32px; padding: 5px 10px"
                    :style="[
                      $i18n.locale == 'ar'
                        ? { 'margin-left': '5px' }
                        : { 'margin-right': '5px' },
                    ]"
                    :title="$t('app.copyLink')"
                  >
                    <i class="mdi mdi-link font-size-16"></i>
                  </button>
                </td>

                <td v-if="hasActions">
                  <div class="col-12">
                    <button
                      v-if="hasPDF"
                      type="button"
                      @click="toReceipt(item.encrypt_id)"
                      class="btn btn-bg-primary btn-active-color-warning btn-sm"
                      style="width: 32px; height: 32px; padding: 5px 10px"
                      :style="[
                        $i18n.locale == 'ar'
                          ? { 'margin-left': '5px' }
                          : { 'margin-right': '5px' },
                      ]"
                      :title="$t('app.pdf')"
                    >
                      <i class="mdi mdi-file-pdf font-size-16"></i>
                    </button>

                    <CardReturnVoucherUpload
                      v-if="
                        hasReturnedVoucherButton &&
                        ['paid', 'returned'].includes(item.payment_status)
                      "
                      :invoice_id="item.encrypt_id"
                      :returned_voucher_base64="item.returned_voucher.url"
                    />

                    <button
                      v-if="
                        hasReturnedVoucherButton &&
                        ['paid', 'returned'].includes(item.payment_status)
                      "
                      type="button"
                      data-bs-toggle="modal"
                      :data-bs-target="
                        '#kt_modal_return_voucher_upload' + item.encrypt_id
                      "
                      @click="uploadVoucher"
                      class="
                        btn btn-bg-primary btn-active-color-secondary btn-sm
                      "
                      style="width: 32px; height: 32px; padding: 5px 10px"
                      :style="[
                        $i18n.locale == 'ar'
                          ? { 'margin-left': '5px' }
                          : { 'margin-right': '5px' },
                      ]"
                      :title="$t('app.upload_voucher')"
                    >
                      <i class="mdi mdi-receipt font-size-16"></i>
                    </button>

                    <button
                      v-if="
                        permissions.edit &&
                        !hasNoEdit &&
                        !['paid', 'returned'].includes(item.payment_status)
                      "
                      type="button"
                      @click="editItem(item.encrypt_id)"
                      class="
                        btn btn-bg-success btn-active-color-secondary btn-sm
                      "
                      style="width: 32px; height: 32px; padding: 5px 10px"
                      :style="[
                        $i18n.locale == 'ar'
                          ? { 'margin-left': '5px' }
                          : { 'margin-right': '5px' },
                      ]"
                      :title="$t('app.edit')"
                    >
                      <i class="mdi mdi-pencil font-size-16"></i>
                    </button>
                    <button
                      v-if="hasShowEntry"
                      type="button"
                      @click="editItem(item.encrypt_id)"
                      class="btn btn-bg-success btn-active-color-primary btn-sm"
                      style="width: 32px; height: 32px; padding: 5px 10px"
                      :style="[
                        $i18n.locale == 'ar'
                          ? { 'margin-left': '5px' }
                          : { 'margin-right': '5px' },
                      ]"
                      :title="$t('app.show')"
                    >
                      <i class="mdi mdi-eye font-size-16"></i>
                    </button>

                    <button
                      v-if="hasReport && permissions.edit"
                      type="button"
                      @click="analyticsItem(item.encrypt_id)"
                      class="btn btn-bg-primary btn-active-color-warning btn-sm"
                      style="width: 32px; height: 32px; padding: 5px 10px"
                      :style="[
                        $i18n.locale == 'ar'
                          ? { 'margin-left': '5px' }
                          : { 'margin-right': '5px' },
                      ]"
                      title="Analytics"
                    >
                      <i
                        class="bx bx-line-chart font-size-17"
                        style="padding: 6px"
                      ></i>
                    </button>
                    <button
                      type="button"
                      v-if="hasInvoices"
                      @click="
                        item.loading = true;
                        toUserInvoices(item.encrypt_id);
                      "
                      class="btn btn-bg-primary btn-active-color-warning btn-sm"
                      style="width: 32px; height: 32px; padding: 5px 10px"
                      :style="[
                        $i18n.locale == 'ar'
                          ? { 'margin-left': '5px' }
                          : { 'margin-right': '5px' },
                      ]"
                      :title="$t('nav.invoices')"
                    >
                      <i class="bx bx-money font-size-16"></i>
                    </button>

                    <button
                      type="button"
                      v-if="
                        !item.loading &&
                        item.role_name != 'manager' &&
                        permissions.delete &&
                        !hasNoDelete &&
                        hasAccept &&
                        item.status != 0
                      "
                      @click="
                        item.loading = true;
                        deleteItem(item.encrypt_id);
                      "
                      class="btn btn-bg-danger btn-active-color-danger btn-sm"
                      style="width: 32px; height: 32px; padding: 5px 10px"
                      :title="$t('role.delete')"
                    >
                      <i class="mdi mdi-delete font-size-17"></i>
                    </button>
                    <button
                      type="button"
                      v-if="
                        !item.loading &&
                        item.id != 'manager' &&
                        hasSuspend &&
                        item.suspend == 0
                      "
                      @click="
                        item.loading = true;
                        suspendItem(item.encrypt_id);
                      "
                      class="btn btn-bg-warning btn-active-color-primary btn-sm"
                      style="width: 32px; height: 32px; padding: 5px 10px"
                      :title="$t('role.block')"
                    >
                      <i class="mdi mdi-do-not-disturb font-size-19"></i>
                    </button>
                    <button
                      type="button"
                      v-if="!item.loading && hasSuspend && item.suspend == 1"
                      @click="
                        item.loading = true;
                        unSuspendItem(item.encrypt_id);
                      "
                      class="
                        btn btn-bg-secondary btn-active-color-primary btn-sm
                      "
                      style="width: 32px; height: 32px; padding: 0"
                      :title="$t('role.unblock')"
                    >
                      <i class="mdi mdi-do-not-disturb-off font-size-19"></i>
                    </button>
                    <button
                      type="button"
                      v-if="
                        !item.loading &&
                        item.role_name != 'manager' &&
                        hasDeleteButton &&
                        permissions.delete
                      "
                      @click="
                        item.loading = true;
                        deleteItem(item.encrypt_id);
                      "
                      class="btn btn-bg-danger btn-active-color-danger btn-sm"
                      style="width: 32px; height: 32px; padding: 5px 10px"
                      :title="$t('role.delete')"
                    >
                      <i class="mdi mdi-delete font-size-17"></i>
                    </button>
                    <span
                      v-if="
                        !item.loading &&
                        item.status == 3 &&
                        item.approved_by_user == 0
                      "
                    >
                      <span
                        class="
                          btn
                          btn-bg-primary
                          btn-color-muted
                          btn-active-color-primary
                          btn-sm
                        "
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                        style="padding: 10px"
                      >
                        {{ $t("app.waiting_for_customer_approve") }}
                      </span>
                    </span>
                    <span
                      v-if="
                        !item.loading &&
                        item.status == 3 &&
                        item.approved_by_user == 1
                      "
                    >
                      <span
                        class="
                          btn
                          btn-bg-success
                          btn-color-muted
                          btn-active-color-success
                          btn-sm
                        "
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                        style="padding: 10px"
                      >
                        {{ $t("app.customer_has_approve") }}
                      </span>
                    </span>
                    <span
                      v-if="
                        !item.loading &&
                        item.status == 3 &&
                        item.approved_by_user == 2
                      "
                    >
                      <span
                        class="
                          btn
                          btn-bg-danger
                          btn-color-muted
                          btn-active-color-danger
                          btn-sm
                        "
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                        style="padding: 10px"
                      >
                        {{ $t("app.customer_has_decline") }}
                      </span>
                    </span>
                    <p v-if="item.status == 1" class="mt-1">
                      <span
                        class="
                          btn
                          btn-bg-danger
                          btn-color-muted
                          btn-active-color-danger
                          btn-sm
                        "
                        v-if="item.contacted == 0"
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                        style="padding: 10px"
                      >
                        {{ $t("app.lecturer_not_called") }}
                      </span>
                      <span
                        class="
                          btn
                          btn-bg-warning
                          btn-color-muted
                          btn-active-color-warning
                          btn-sm
                        "
                        v-if="item.contacted == 1"
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                        style="padding: 10px"
                      >
                        {{ $t("app.lecturer_has_called") }}
                        <br />
                        {{ $t("app.user_not_answer_yet") }}
                      </span>
                      <span
                        class="
                          btn
                          btn-bg-success
                          btn-color-muted
                          btn-active-color-success
                          btn-sm
                        "
                        v-if="item.contacted == 2"
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                        style="padding: 10px"
                      >
                        {{ $t("app.lecturer_has_called") }}
                        <br />
                        {{ $t("app.user_has_answer") }}
                      </span>
                    </p>
                    <p
                      v-if="
                        !permissions.edit &&
                        !permissions.delete &&
                        refs != 'reports'
                      "
                    >
                      <span
                        class="
                          btn
                          btn-bg-danger
                          btn-color-muted
                          btn-active-color-danger
                          btn-sm
                        "
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                        style="padding: 10px"
                      >
                        <i class="fas fa-exclamation-triangle"></i>
                        {{ $t("view.noPermissionGranted") }}
                      </span>
                    </p>
                    <span v-if="item.loading">
                      <span
                        class="spinner-grow spinner-grow-sm mr-1"
                        role="status"
                        aria-hidden="true"
                      >
                      </span>
                    </span>
                    <span v-if="item.is_over">
                      <span
                        class="
                          btn
                          btn-bg-danger
                          btn-color-muted
                          btn-active-color-danger
                          btn-sm
                        "
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                        style="padding: 10px"
                      >
                        {{ $t("view.date_is_old") }}
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <p></p>
      <Pagination
        v-if="!dataLoading"
        :items="items"
        :pageCount="pageCount"
        v-on:pageChange="handelPageChange"
      >
      </Pagination>
    </div>
  </div>
</template>

<script lang="js">
import iziToast from "izitoast";
import "moment-duration-format";
import { defineComponent } from "vue";
import Pagination from "@/components/Pagination.vue";
import BulkAction from "@/components/BulkAction.vue";
import mitt from "mitt";
import { headerTheme } from "@/core/helpers/config";
import CardReturnVoucherUpload from "@/components/CardReturnVoucherUpload";

const bus = mitt();

export default defineComponent({
  name: "Table",
  props: [
    "refs",
    "hasReportDropDown",
    "colspan",
    "watchCountryId",
    "locale",

    "hasBulkAction",
    "hasExport",
    "hasInvoices",
    "hasShowEntries",
    "hasSearch",
    "hasRoleFilter",
    "hasCompanyFilter",
    "hasCompany",
    "company",

    "hasInvoiceKey",
    "invoice_key",

    "hasPaymentStatus",
    "payment_status",

    "hasPaymentMethod",
    "payment_method",

    "hasSearchFilter",
    "hasDateFilter",
    "hasDateFromFilter",
    "hasDateToFilter",
    "hasTimeFromFilter",
    "hasTimeToFilter",
    "hasPaidFilter",
    "hasStatusApproved",
    "hasStatusFilter",

    "roleWhereNotIn",

    "hasHashId",
    "lblSearch",

    "role_name",

    "hasUser",
    "isAdmin",
    "isAdmin2",

    "hasImage",
    "image",
    "image_style",

    "hasPDF",

    "hasModule",
    "module",

    "hasEndDate",
    "end_date",

    "hasStartDate",
    "start_date",

    "hasDeleteButton",
    "hasReturnedVoucherButton",

    "hasName",
    "name",

    "hasPaidCountStatistics",
    "hasPaidAmountStatistics",

    "hasSubject",
    "subject",

    "hasCivilID",
    "civilID",

    "hasTitle",
    "title",

    "hasDetail",
    "detail",

    "hasPrice",
    "price",

    "hasEmail",
    "email",

    "hasCountryCode",
    "countryCode",

    "hasMobile",
    "mobile",

    "hasTotal",
    "totalPrice",

    "hasRelation",
    "relation",

    "hasRole",
    "roleLabel",

    "hasPaid",
    "hasDate",
    "hasStatus",
    "hasTotalPaidInvoicesCount",
    "hasShareButtons",
    "hasStatus2",
    "hasActions",
    "hasSeen",
    "hasNoEdit",
    "hasShowEntry",
    "hasAccept",
    "hasDecline",
    "hasNoDelete",
    "hasReport",
    "actionable",
    "sortable",
    "hasSuspend",
    "addNew",
    "hasPrintCompanyInvoices",
  ],
  components: {
    CardReturnVoucherUpload,
    Pagination,
    BulkAction,
  },
  data() {
    return {
      domainName: window.domainName,

      auth: {
        role: "",
        id_token: "",
      },
      fields: this.$props.fields,
      send_invoice: "",
      selected: [],
      selectAll: false,

      company_id: "",
      user_id: "",
      invoice_id: "",
      parent_id: "",
      slug: "",

      permissions: {
        add: false,
        edit: false,
        delete: false,
      },
      search: "",
      status: "",
      target: "",
      date: "",
      paid: "",
      date_from: "",
      date_to: "",
      time: "",
      time_from: "",
      time_to: "",
      period: "",
      filter_by: "",
      filter: "",
      order: "",
      order_by: "",
      role: this.$props.role_name ? this.$props.role_name : "",

      dataLoading: true,
      bulkLoading: true,
      exportLoading: true,
      orderLoading: false,
      invoiceSocialLoading: false,

      roleLoading: true,
      role_id: "",
      roles: [],

      pending_count: 0,
      returned_count: 0,
      paid_count: 0,

      pending_amount: 0,
      returned_amount: 0,
      paid_amount: 0,

      categoryLoading: false,
      category_id: "",
      categories: [],
      companies: [],
      currency: "",
      items: [],
      total: 0,
      page: 1,
      pageCount: 10,
      bus,
      country_id: this.$props.watchCountryId,
    };
  },
  watch: {
    watchCountryId: function (new_val) {
      this.country_id = new_val;
      this.fetchData(true);
    },
    locale: function () {
      this.fetchData(true);
    },
    $route() {
      if (this.$route.params.type) {
        this.fetchData();
      }
    },
  },
  created() {
    // accessToken
    if (localStorage.getItem("id_token")) {
      this.currency = localStorage.getItem("currency_key");
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (localStorage.getItem("role_key")) {
      this.auth.role = localStorage.getItem("role_key");
    }
    if (this.$route.query.parent_id) {
      this.parent_id = this.$route.query.parent_id;
    }

    if (this.$route.query.slug) {
      this.slug = this.$route.query.slug;
    }

    if (this.$route.query.model_id && this.$route.query.type == "users") {
      this.user_id = this.$route.query.model_id;
    }

    if (this.$route.query.model_id && this.$route.query.type == "companies") {
      this.company_id = this.$route.query.model_id;
    }

    if (this.$route.params.filter_by) {
      this.filter_by = this.$route.params.filter_by;
    }

    if (this.$route.params.filter) {
      this.filter = this.$route.params.filter;
    }
    // fetchData
    this.fetchData();

    if (this.$props.hasRoleFilter) {
      this.fetchRoles();
    }

    if (this.$props.hasCompanyFilter) {
      this.fetchCompanies();
    }


  },
  setup(){
    return{
      headerTheme
    }
  },
  methods: {
    sendInvoiceSocial(send_invoice , item) {
      if (send_invoice == 5) {
        return this.copyLink(
            item.link
        );
      }
      this.invoiceSocialLoading = true;

      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: "/invoices/social-links/" + item.encrypt_id + "/send",
        method: "POST",
        data: {},
        params: {
          paginate: 100,
          send_invoice: send_invoice,
        },
      };
      this.axios(options)
        .then(() => {
            iziToast.success({
              icon: "bx bx-send",
              title: "",
              message: "تم الإرسال",
            });
        })
        .catch((err) => {
          this.roleLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        }).finally(()=>{
        this.invoiceSocialLoading = false;
      });
    },
    fetchData(loading = false) {
      if (this.company_id) {
        const company = this.companies.find(
          (company) => company.encrypt_id == this.company_id
        );
        if (company)
          this.bus.emit("companyChanged", { company_id: company.encrypt_id });
      }

      if (loading) {
        this.dataLoading = true;
      }
      (this.plural = ""), (this.selectAll = false);
      this.selected = [];

      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
        locale: this.$i18n.locale,
      };
      const options = {
        url: this.$props.refs,
        method: "GET",
        data: {},
        params: {
          page: this.page,
          paginate: this.perPage,
          parent_id: this.parent_id,
          category_id: this.category_id,
          country_id: this.country_id,
          status: this.status,
          company_id: this.company_id,
          user_id: this.user_id,
          target: this.target,
          date: this.date,
          end_date: this.end_date,
          start_date: this.start_date,
          period: this.period,
          paid: this.paid,
          date_from: this.date_from,
          date_to: this.date_to,
          time: this.time,
          time_from: this.time_from,
          time_to: this.time_to,
          [this.filter_by]: this.filter,
          search: this.search,
          order: this.order,
          order_by: this.order_by,
          role: this.role,
        },
      };
      this.axios(options)
        .then((res) => {
          this.dataLoading = false;
          this.bulkLoading = false;
          this.exportLoading = false;
          this.orderLoading = false;
          this.categoryLoading = false;
          this.invoiceSocialLoading = false;

          this.items = res.data.data.rows;
          this.total = res.data.data.paginate.total;

          this.paid_count = res.data.data.paid;
          this.pending_count = res.data.data.pending;
          this.returned_count = res.data.data.returned;

          this.paid_amount = res.data.data.paid_amount;
          this.pending_amount = res.data.data.pending_amount;
          this.returned_amount = res.data.data.returned_amount;

          this.permissions.add = res.data.data.permissions.add;
          this.$emit("hasAddNewPermission", this.permissions.add);

          this.permissions.edit = res.data.data.permissions.edit;
          this.permissions.delete = res.data.data.permissions.delete;

          if (res.data.data.paginate.total) {
            this.pageCount = Math.ceil(
              res.data.data.paginate.total / this.perPage
            );
          }
        })
        .catch((err) => {
          //403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "ti-alert",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    fetchCompanies() {
      this.roleLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: "/companies",
        method: "GET",
        data: {},
        params: {
          paginate: 100,
        },
      };
      this.axios(options)
        .then((res) => {
          this.roleLoading = false;
          this.companies = res.data.data.rows;
        })
        .catch((err) => {
          this.roleLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    fetchRoles() {
      this.roleLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: "/roles",
        method: "GET",
        data: {},
        params: {
          status: "active",
          whereNotIn: this.$props.roleWhereNotIn,
          paginate: 100,
        },
      };
      this.axios(options)
        .then((res) => {
          this.roleLoading = false;
          this.roles = res.data.data.rows;
        })
        .catch((err) => {
          this.roleLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },
    // clear Local Storages
    clearLocalStorage() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("user_image");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("role");
    },

    clearDate() {
      this.date = "";
      this.fetchData();
    },

    clearDateFrom() {
      this.date_from = "";
      this.fetchData();
    },

    clearDateTo() {
      this.date_to = "";
      this.fetchData();
    },

    clearTime() {
      this.time = "";
      this.fetchData();
    },

    clearTimeFrom() {
      this.time_from = "";
      this.fetchData();
    },

    clearTimeTo() {
      this.time_to = "";
      this.fetchData();
    },

    // Handle Change from child components
    handelPageChange(event) {
      this.page = event;
      this.fetchData();
    },

    editItem(id) {
      if (this.$route.query.parent_id) {
        this.$router.push({
          name: "edit-" + this.$props.refs,
          params: { id: id },
          query: { parent_id: this.parent_id, slug: this.slug },
        });
      } else {
        this.$router.push({
          name: "edit-" + this.$props.refs,
          params: { id: id },
        });
      }
    },
    uploadVoucher() {
        const modal = document.getElementById("kt_modal_return_voucher_upload");
        modal.classList.add('show');
        modal.setAttribute("style", "display: block;");

      const exits = modal.querySelectorAll('.modal-exit');
        exits.forEach(function (exit) {
          exit.addEventListener('click', function (event) {
            event.preventDefault();
            modal.classList.remove('show');
          });
        });
    },

    toReceipt(id) {
      window.open(window.domainName + "/invoices/pdf/" + id + "?locale=" + this.$i18n.locale, "_blank");
    },

    analyticsItem(id) {
      if (this.$route.query.parent_id) {
        this.$router.push({
          name: "reports-" + this.$props.refs,
          params: { id: id },
          query: { parent_id: this.parent_id, slug: this.slug },
        });
      } else {
        this.$router.push({
          name: "analytics-" + this.$props.refs,
          params: { id: id },
        });
      }
    },

    deleteItem(id) {
      if (confirm("Are you sure?")) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.id_token,
        };
        const options = {
          url: this.$props.refs + "/" + id,
          method: "DELETE",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData(true);
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message: "تم الحذف بنجاح",
            });
          })
          .catch((err) => {
            this.fetchData(true);
            iziToast.error({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          });
      } else {
        // in case pressed cancel
        this.fetchData(true);
      }
    },

    suspendItem(id) {
      if (confirm("Are you sure?")) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.id_token,
        };
        const options = {
          url: this.$props.refs + "/suspend/" + id,
          method: "POST",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData(true);
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message: "تم تعليق الحساب بنجاح",
            });
          })
          .catch((err) => {
            this.fetchData(true);
            iziToast.error({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          });
      } else {
        // in case pressed cancel
        this.fetchData(true);
      }
    },

    unSuspendItem(id) {
      if (confirm("Are you sure?")) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.id_token,
        };
        const options = {
          url: this.$props.refs + "/unsuspend/" + id,
          method: "POST",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData(true);
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message: "تم إلغاء تعليق الحساب بنجاح",
            });
          })
          .catch((err) => {
            this.fetchData(true);
            iziToast.error({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          });
      } else {
        // in case pressed cancel
        this.fetchData(true);
      }
    },

    submitModal() {
      this.editStatusItem(this.saved_id, this.saved_status);
    },

    toUserInvoices(user_id) {
      this.$router.push({name: "invoices" , query:{model_id: user_id , type: this.refs}})
    },

    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.items) {
          this.selected.push(this.items[i].id);
        }
      }
    },

    activate(id) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs + "/active/" + id,
        method: "POST",
        data: {},
      };
      this.axios(options)
        .then(() => {
          this.fetchData();
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم التنشيط بنجاح",
          });
        })
        .catch((err) => {
          this.fetchData();
          iziToast.error({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    inactivate(id) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs + "/inactive/" + id,
        method: "POST",
        data: {},
      };
      this.axios(options)
        .then(() => {
          this.fetchData();
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم إلغاء التنشيط بنجاح",
          });
        })
        .catch((err) => {
          this.fetchData();
          iziToast.error({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    handelActiveChange() {
      if (this.selected.length == 0) {
        alert("من فضلك أختر العناصر اولاُ");
      } else {
        this.bulkLoading = true;
        this.activate(this.selected.join());
      }
    },

    handelInActiveChange() {
      if (this.selected.length == 0) {
        alert("من فضلك أختر العناصر اولاُ");
      } else {
        this.bulkLoading = true;
        this.inactivate(this.selected.join());
      }
    },
    copyLink(url) {
      var textArea = document.createElement("textarea");
      textArea.style.position = "fixed";
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      iziToast.success({
        icon: "ti-check",
        title: "",
        message: "تم النسخ",
      });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn,
i {
  color: #fff;
}

.form-control {
  padding: 0.75rem 1.5rem;
}
.search-icon {
  position: absolute;
  right: 5px;
  top: 40%;
}
.no-border,
.no-border th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
</style>

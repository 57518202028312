<template>
  <div>
    <paginate
      v-if="!dataLoading && items.length !== 0"
      :page-count="pageCount"
      :prev-text="$t('app.Prev')"
      :next-text="$t('app.Next')"
      :container-class="'pagination'"
      :click-handler="handleChange"
    >
    </paginate>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Pagination",
  props: ["items", "pageCount", "dataLoading"],
  data() {
    return {
      //
    };
  },
  methods: {
    //
    handleChange(event) {
      this.$emit("pageChange", event);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <div>
    <div>
      <el-select
        v-model="perPageTrans"
        class="form-select-solid"
        :placeholder="$t('app.export')"
      >
        <el-option
          v-for="(entry, index) in entries"
          :key="index"
          :label="$t('app.show') + perPage"
          :value="$t('app.show') + perPage"
        >
          <a
            class="dropdown-item"
            :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
            @click="handleChange(entry)"
          >
            {{ entry }}
          </a>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ShowEntries",
  props: ["perPage"],
  data() {
    return {
      //
      perPageTrans: this.$t("app.show") + this.$props.perPage,
      current: this.$props.perPage,
      entries: [10, 25, 50, 100],
    };
  },
  methods: {
    //
    handleChange(event) {
      this.current = event;
      this.$emit("showChange", event);
    },
  },
});
</script>

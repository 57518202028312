<template>
  <div class="card">
    <div class="card-body">
      <div class="mt-3">
        <div class="row col-12">
          <div class="row col-11">
            <div class="col-12 form-group">
              <label
                class="control-label"
                :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
              >
                {{ labelAttachment }}
              </label>

              <input
                class="form-control"
                type="file"
                v-on:change="onFileChange($event)"
              />

              <p v-if="row.file" style="margin-top: 5px">
                <a
                  :href="row.file"
                  target="_blank"
                  class="btn btn-success btn-sm"
                  :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
                  style="color: #fff"
                >
                  {{ $t("app.show_attachment") }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAttachment",
  props: ["labelAttachment", "attachment"],
  data() {
    return {
      row: {
        // Files
        file: this.$props.attachment ? this.$props.attachment : "",
        file_path: "",
      },
    };
  },
  mounted() {
    this.row.file_path = this.row.file;
  },
  created() {
    //
  },
  methods: {
    //
    addMore() {
      this.row.file_key++;
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.createBase64File(file);
    },

    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.file_path = e.target.result;
        this.changeData();
      };
    },
    changeData() {
      // files
      this.row.file = this.row.file_path;

      let data = {
        attachment: this.row.file,
      };
      this.$emit("attachmentChange", data);
    },
  },
};
</script>
